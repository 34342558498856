@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  .no-tailwindcss-base, .no-tailwindcss-base * , .no-tailwindcss-base > * {
    font-size: revert;
    font-weight: revert;
    margin: revert;
    display: revert;
    vertical-align: revert;
    max-width: revert;
    height: revert;
    border-width: revert;
    border-style: revert;
    border-color: revert;
    border-style: revert;
    outline: revert;
    list-style: revert;
    padding: revert;
  }
}

html {
  height: 100%
}

body {
  background-color: #F8F8F8;
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
}
